<template>
  <form @submit.prevent="updateSchedule">
    <modal-card :title="`Update task schedule`" :processing="processing">
      <b-field label="Date*">
        <date-time-picker
          :value="$moment(date).format('YYYY-MM-DD HH:mm')"
          :min-date="$moment().format('YYYY-MM-DD HH:mm')"
          :disabled="processing"
          format="YYYY-MM-DD HH:mm"
          @input="date = $moment($event, 'YYYY-MM-DD HH:mm').toDate()"
        />
      </b-field>
      <button
        slot="footer"
        :class="{ 'is-loading': processing }"
        :disabled="!validForm || processing"
        type="submit"
        class="button is-success"
      >
        Submit
      </button>
    </modal-card>
  </form>
</template>

<script>
export default {
  name: "ScheduleControlModal",
  props: {
    taskId: {
      type: String,
      required: true
    },
    dateScheduled: {
      type: Date,
      required: true
    }
  },
  data() {
    return {
      processing: false,
      date: this.dateScheduled
    };
  },
  computed: {
    task() {
      return this.$store.getters["tasks/task"](this.taskId);
    },
    validForm() {
      return !!this.date && this.$moment(new Date(this.date)).isValid();
    }
  },
  methods: {
    updateSchedule() {
      this.processing = true;

      this.$store
        .dispatch("tasks/updateSchedule", {
          taskId: this.taskId,
          dateScheduled: this.date.toString()
        })
        .then(result => {
          this.processing = false;
          this.$emit("close");
          this.$toast.open({
            message: result.message,
            position: "is-bottom",
            queue: false,
            type: "is-success"
          });
        })
        .catch(error => {
          this.processing = false;

          this.$toast.open({
            message: error.message,
            position: "is-bottom",
            queue: false,
            type: "is-danger"
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.modal .animation-content .modal-card {
  overflow: visible !important;
}

.modal-card-body {
  overflow: visible !important;
}
</style>
